<template>
  <v-card class="d-flex flex-wrap" max-width="155px">
    <v-card
      outlined
      v-for="(rede_social, index) in redes_sociais"
      :key="index"
      @click="adicionar(rede_social.icone)"
      height="30px"
      width="30px"
      class="ma-1 pa-1"
      hover
    >
      <v-img contain :src="rede_social.icone"></v-img>
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: "IconesRedesSociais",
  props: {
    selected: {
      type: String,
    },
  },

  data() {
    return {
      selectedRedeSocial: null,
      redes_sociais: [
        {
          rede_social: "facebook",
          icone:
            "https://tocs-agency-ole.s3-sa-east-1.amazonaws.com/icodes-redes-sociais/facebook.svg",
        },
        {
          rede_social: "instagram",
          icone:
            "https://tocs-agency-ole.s3-sa-east-1.amazonaws.com/icodes-redes-sociais/instagram.svg",
        },
        {
          rede_social: "linkedin",
          icone:
            "https://tocs-agency-ole.s3-sa-east-1.amazonaws.com/icodes-redes-sociais/linkedin.svg",
        },
        {
          rede_social: "pinterest",
          icone:
            "https://tocs-agency-ole.s3-sa-east-1.amazonaws.com/icodes-redes-sociais/pinterest.svg",
        },
        {
          rede_social: "tik-tok",
          icone:
            "https://tocs-agency-ole.s3-sa-east-1.amazonaws.com/icodes-redes-sociais/tik-tok.svg",
        },
        {
          rede_social: "twitter",
          icone:
            "https://tocs-agency-ole.s3-sa-east-1.amazonaws.com/icodes-redes-sociais/twitter.svg",
        },
        {
          rede_social: "whatsapp",
          icone:
            "https://tocs-agency-ole.s3-sa-east-1.amazonaws.com/icodes-redes-sociais/whatsapp.svg",
        },
        {
          rede_social: "youtube",
          icone:
            "https://tocs-agency-ole.s3-sa-east-1.amazonaws.com/icodes-redes-sociais/youtube.svg",
        },
      ],
    };
  },
  methods: {
    adicionar(item) {
      this.$emit("update:selected", item);
    },
  },
};
</script>

<style lang="scss"></style>
